<template>
  <div
    class="components_common_thumb_task_page"
  >
    <div
      class="new_content_group"
      :class="{isimportant: item.isimportant && 0, isdo: item.isdo && !item.isimportant && 0, both: item.isimportant && item.isdo && 0}"
      v-if="1"
      @click="openTaskBox"
    >
      <div class="select_group cp"
        v-show="open_select"
        :class="{active: item.cf_selected}"
        @click.stop="touchSelect()"
      ></div>
      <div class="color_group"
        v-if="item.colour"
      >
        <template
          v-for="(item,index) in getColorList(item)"
        >
          <div
            class="color"
            :key="index"
            :style="{'border-left-color': item}"
          ></div>
        </template>

      </div>
      <div
        class="dot"
        v-show="item.dot"
      ></div>
      <div class="info_group">
        <div class="info_box">
          <template>
            <div class="info cp un_sel">
              <title-status-tag
                class="status_tag_group"
                :level-status="String(item.levelStatus)"
                :process-status="String(item.processStatus)"
                size="14px"
              ></title-status-tag>
              <span class="task_name">
                {{ item.taskDetail || item.name || item.taskName }}
              </span>
            </div>
          </template>
          <el-popover
            placement="bottom-end"
            trigger="click"
            popper-class="components_common_thumb_task_page_popover"
            :visible-arrow="false"
            v-model="switch_more_btn"
            @show="handleShowMenu"
            v-if="menu"
          >
            <div
              class="more_list"
            >
              <div
                v-if="canSetStatus"
                class="list_group un_sel cp"
                @click="switch_more_btn=false;touchSetStatusTag();"
              >
                <!-- <img src="~@/assets/images/task/task_share.png" alt="" class="icon"> -->
                <div class="text">
                  设置状态
                </div>
              </div>
              <div
                v-if="permissionOfMigrateTask"
                class="list_group un_sel cp"
                @click="switch_more_btn=false;touchMigrateTask();"
              >
                <div
                  class="text"
                >
                  任务迁移
                </div>
              </div>
              <div
                v-if="canCall"
                class="list_group un_sel cp"
                @click="switch_more_btn=false;touchCall();"
              >
                <div class="text">
                  调用
                </div>
              </div>
              <template
                v-if="!recycle"
              >
                <div
                  v-if="canSetLabel"
                  class="list_group un_sel cp"
                  @click="switch_more_btn=false;openTagBox();"
                >
                  <!-- <img src="~@/assets/images/fun_template/more_menu_tag.png" alt="" class="icon"> -->
                  <div class="text">设置标签</div>
                </div>
                <div
                  v-if="canShare"
                  class="list_group un_sel cp"
                  @click="switch_more_btn=false;doShare();"
                >
                  <!-- <img src="~@/assets/images/task/task_share.png" alt="" class="icon"> -->
                  <div class="text">分享任务</div>
                </div>
                <div
                  v-if="canCollect"
                  class="list_group un_sel cp"
                  @click="switch_more_btn=false;touchCollect()"
                >
                  <div class="text">{{`${item.collectionflag? '取消':''}`}}</div>
                </div>
                <template
                  v-if="sell"
                >
                  <div class="list_group un_sel cp"
                    v-if="putaway_status"
                    @click="switch_more_btn=false;doPutaway();"
                  >
                    <div class="text">上架至商城</div>
                  </div>
                  <div class="list_group un_sel cp"
                    v-if="sold_out_status"
                    @click="switch_more_btn=false;doSoldOut();"
                  >
                    <div class="text">从商城下架</div>
                  </div>
                </template>

                <div class="list_group un_sel cp"
                  v-if="0"
                  @click="switch_more_btn=false;"
                >
                  <!-- <img src="~@/assets/images/fun_template/more_menu_transmit.png" alt="" class="icon"> -->
                  <div class="text">转发任务</div>
                </div>
              </template>
              <template
                v-if="recycle"
              >
                <div class="list_group un_sel cp no_icon"
                  @click="recoverTask();"
                >
                  <div class="text">恢复</div>
                </div>
                <div class="list_group un_sel cp no_icon"
                  @click="completelyDelete();"
                >
                  <div class="text">彻底删除</div>
                </div>
              </template>
            </div>
            <div class="more_btn cp"
              slot="reference"
              @click="$api.stopPropagation($event);"
            ></div>
          </el-popover>

        </div>

        <div class="time_box d-flex align-center justify-space-between"
          v-if="!recycle && show_time"
        >
          <div
            class="time"
          >
            {{item.updateTime | universalDate}}
          </div>
          <div
            v-if="showCheckStatus || 1"
            class="check_status_group"
            :class="[checkType]"
          >
            {{ checkText }}
          </div>
        </div>
        <!-- 剩余天数 -->
        <div class="time_box"
          v-if="recycle"
        >{{item.deleteDays !== null ? `剩余${item.deleteDays}天` : timeRemaining(item.updateTime)}}</div>

      </div>
      <div class="template_info_group"
        :class="{separate}"
      >
        <div
          v-if="item.taskType == 2 && item.draft !== 1"
        ></div>
        <div
          class="avatar_group"
          v-if="(item.taskType != 2 || item.draft === 1) && (item.createuserthumbnail || item.updateuserthumbnail)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.createusername"
            placement="bottom"
            :open-delay="500"
          >
            <img
              :src="`${$avatar_url}${ item.createuserthumbnail || item.updateuserthumbnail}@!small200`"
              alt=""
              class="avatar"
            >
          </el-tooltip>
          <img
            src="~@/assets/images/dialog/new_chat_icon.gif"
            alt=""
            class="new_chat_icon"
            v-show="item.taskchatnum && 0"
          >
        </div>
        <div class="tag_box"
          v-if="!sell && show_tag || 1"
        >
          <div class="tag reminder"
            v-show="item.isremind"
          ></div>
          <div
            v-if="0"
            class="tag finance"
          >
            财
          </div>
          <div
            class="tag draft"
            v-show="draft"
          >草</div>
          <div
            class="tag teach"
            v-show="item.taskType == 2 || item.taskType == 3"
          >教</div>
          <div
            v-if="item.hide"
            class="tag privacy"
          >私</div>
        </div>
        <!-- <div
          v-if="sell"
          class="check_status"
          :class="[transformCheckStaus(item.checkstatus).class_name]"
        >
          {{transformCheckStaus(item.checkstatus).text}}
        </div> -->
        <div
          class="teaching_group d-flex align-center justify-space-between"
          v-if="show_teaching && 0"
        >
          <div class="audit"
            v-if="show_audit"
            :style="{
              color: item.getAuditStatus() ? item.getAuditStatus().color : '#999',
            }"
          >{{item.getAuditStatus() ? item.getAuditStatus().audit : ''}}</div>
          <img
            v-if="show_putaway && item.showflag"
            src="~@/assets/images/task_thumb/putaway.png"
            alt=""
            class="putaway"
          >
        </div>
      </div>
    </div>
    <div
      class="from_group cp"
      @click="openTaskBox"
      v-if="show_from && item.from"
    >
      <div class="tag">来源:</div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="item.from"
        placement="bottom-start"
        :open-delay="500"
      >
        <div class="from">{{item.from}}</div>
      </el-tooltip>

    </div>
  </div>
</template>
<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
import global from '@/api/global.js'
import tools from '@/api/tools'
import store from '@/store'
export default {
  components: {
    TitleStatusTag
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_from: {
      type: Boolean,
      default: false,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
    show_draft: {
      type: Boolean,
      default: false,
    },
    show_time: {
      type: Boolean,
      default: true,
    },
    show_tag: {
      type: Boolean,
      default: true,
    },
    // 分隔条
    separate: {
      type: Boolean,
      default: true,
    },
    show_teaching: {
      type: Boolean,
      default: false,
    },
    show_audit: {
      type: Boolean,
      default: false,
    },
    show_putaway: {
      type: Boolean,
      default: false,
    },
    open_select: {
      type: Boolean,
      default: false,
    },
    canSetLabel: { // 是否可以设置标签
      type: Boolean,
      default: true,
    },
    canShare: { // 是否可以分享
      type: Boolean,
      default: true,
    },
    canCollect: { // 是否可以收藏
      type: Boolean,
      default: true,
    },
    // 默认 False
    canSetStatus: { // 是否可以设置状态标签
      type: Boolean,
      default: false,
    },
    canCall: { // 是否可以调用
      type: Boolean,
      default: false,
    }
  },
  computed: {
    showCheckStatus () {
      const { item } = this
      const { checkstatus, taskType, checkType } = item
      if (checkType === 1) {
        return false
      }
      if (taskType !== 2) {
        return false
      }
      if (checkstatus === 3 || checkstatus === 2 || checkstatus === 4) {
        return true
      }
      return false
    },
    checkType () {
      const { item } = this
      const { checkstatus } = item
      if (checkstatus === 2) {
        return ''
      }
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    checkText () {
      const { item } = this
      const { checkstatus, checkType } = item
      if (checkType === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return '待审核'
      }
      if (checkstatus === 3) {
        return '通过'
      }
      if (checkstatus === 4) {
        return '未通过'
      }
      return ''
    },

    /**
     * 迁移任务权限
     * 条件： 工作组 && (工作组创建者 || 任务创建者)
     * @returns {boolean|default.computed.isCreateGroupUser|default.computed.isCreateTaskUser}
     */
    permissionOfMigrateTask () {
      const { isGroup, isCreateGroupUser, isCreateTaskUser } = this
      if (!isGroup) {
        return false
      }
      return isCreateGroupUser || isCreateTaskUser
    },
    isGroup () {
      const { item } = this
      const { chatType } = item
      return Number(chatType) === 1
    },
    isCreateGroupUser () {
      try {
        const user_id = tools.getUserId()
        const { state } = store
        const { chat_detail } = state
        if  (!chat_detail) {
          return false
        }
        const { chat, chatType } = chat_detail
        const { createUserId } = chat
        if (Number(chatType) === 0) {
          return false
        }
        return Number(user_id) === Number(createUserId)
      } catch (e) {
        console.log(e)
        return false
      }
    },
    /**
     * 任务创建者
     * @returns {boolean}
     */
    isCreateTaskUser () {
      const { item } = this
      const { userId } = item
      const user_id = tools.getUserId()
      return Number(user_id) === Number(userId)
    },
    draft () {
      const { show_draft, item } = this
      const { draft } = item
      if (show_draft) {
        if (draft == 1) {
          return true
        }
      }
      return false
    },
    // 可以上架
    putaway_status () {
      const { item}  = this
      const { checkstatus, showflag, taskType } = item
      if (checkstatus === 3 && !showflag && taskType === 2) {
        return true
      }
      return false
    },
    // 可以下架
    sold_out_status () {
      const { item } = this
      const { showflag, taskType } = item
      if (showflag && taskType === 2) {
        return true
      }
      return false
    }
  },
  data(){
    return{
      switch_more_btn: false,
    }
  },
  methods:{
    /**
     * 任务迁移
     */
    touchMigrateTask () {
      const { item } = this
      this.$openMigrateTaskSelectTargetGroup({
        taskList: [ item ]
      })
    },
    handleShowMenu() {
      this.$emit('handleShowMenu', { task: this.item })
    },
    touchCall() {
      this.$emit('handleCall', this.item)
    },
    touchSetStatusTag() {
      this.$openSetStatusTag({
        title: '任务状态',
        pTask: this.item
      })
    },
    openTaskBox(){
      this.$emit('handleClick', this.item);
    },
     /**
     * @description: 获取颜色列表
     * @param {*} item
     * @return {*}
     */
    getColorList(item){
      const {colour} = item;
      if(!colour){
        return '';
      }
      const color_list = colour.split(',');
      return color_list;

    },
    // 剩余天数
    timeRemaining(time){
      // 目标时间
      // const aim_time = this.$moment(time);
      const current_time = this.$moment();
      // 删除时间
      const delete_time = this.$moment(Number(time)+ 30*24*60*60*1000);
      const time_remaining_stamp = Number(delete_time.format('x')) - Number(current_time.format('x'));
      const time_remaining = Math.ceil((time_remaining_stamp/1000/60/60/24).toFixed(2));

      return `剩余${time_remaining}天`;
    },
    closePoppver(){
      this.switch_more_btn=false;
    },
    recoverTask(){
      this.closePoppver();
      this.$emit('handleRecover', this.item);
    },
    completelyDelete(){
      this.closePoppver();
      this.$emit('completelyDelete', this.item);
    },
    openTagBox(){
      this.$emit('handleAddTag', this.item);
    },
    doShare(){
      this.$emit('handleShare', this.item);
    },
    doPutaway(){
      this.$emit('handlePutaway', this.item);
    },
    doSoldOut(){
      this.$emit('handleSoldOut', this.item);
    },
    touchSelect(){
      const { item } = this
      const { cf_selected } = item
      if (cf_selected) {
        item.doDeselect()
      }
      if (!cf_selected) {
        item.doSelect()
      }
      this.$emit('handleSelected', { pTask: item })
    },
    async touchCollect(){
      // TODO 选择文件夹
      const {item} = this
      if(item.collectionflag){
        if(!this.loading){
          this.loading = true


          const res = await this.$global.doPost({
            url: '/projectCustom/deleteFolderTask',
            data: {
              deleteIds: item.id,
            }
          })
          this.loading = false
          if(res.message === 'success'){
            this.item.collectionflag = 0
            this.$notice({desc:"移除成功", type: 'success'})
          }
        }
      }else{
        this.$collectSelectRoute({
          onSure: async (params)=>{
            const {p_folder} = params
            const res = await global.doPost({
              url: '/projectCustom/addFolderTask',
              data: {
                folderId: p_folder.id,
                addIds: item.id,
              }
            })
            if(res.message === 'success'){
              item.collectionflag = 1
              this.$notice({desc:"收藏成功", type: 'success'})
              this.handleClose()
            }
          }
        })
      }
      this.switch_popover = false

      // const {task} = this
      // const {id, collectionflag} = task.task
      // let url = '/projectCustom/addFolderTask'
      // if(collectionflag){
      //   url = '/projectCustom/deleteFolderTask'
      // }
      // if(id){

      // }
    }
  },
  mounted () {
  }

}
</script>
<style lang="scss" scoped>
.components_common_thumb_task_page{
  border-radius:10px;
  width:160px;
  // overflow: hidden;
  .new_content_group{
    @include bbox;
    width: 160px;
    height: 140px;
    background: rgba(244, 244, 244, 1);
    border: 1px solid#E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    // margin-left: 10px;
    // margin-bottom: 20px;
    position: relative;
    z-index: 2;
    .select_group{
      @include transition;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/task_thumb/unselected.png);
      &.active{
        background-image: url(~@/assets/images/task_thumb/selected.png);
      }
    }
    .color_group{
      @include flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      left: 0px;
      .color{
        flex:1;
        width:5px;
        border: none;
        border-left: 4px solid #FFB038;
        &:first-child{
          border-top-left-radius: 20px;
        }
        &:last-child{
          border-bottom-left-radius: 20px;
        }
        &.placeholder{
          border-left-color: transparent;
        }
      }
    }
    .dot {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      background: rgba(255, 59, 48, 1);
      border-radius: 50%;
    }
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .unit_fun_btn{
      @include background(3px 15px);
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 9;
      background-image: url(~@/assets/images/control/fun_more.png);
      top:10px;
      right:2px;
    }
    .info_group {
      @include flexsb;
      @include bbox;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 96px;
      padding: 19px 16px;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      .info_box{
        @include flexsb;
        @include bbox;
        align-items: flex-start;
        width: 100%;
        flex:1;
        height: 100%;
        padding-right: 13px;
        position: relative;
        .info{
          @include multi_line_intercept(2);
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
          text-align: justify;
          text-justify: inter-ideograph;
          text-align-last: left;
          word-break: break-all;
          .status_tag_group{
            margin-bottom: 2px;
            .tag{
              margin-bottom: 2px;
            }
          }
        }
        .more_btn{
          @include background;
          position: absolute;
          width: 16px;
          height: 16px;
          background: {
            image: url(~@/assets/images/control/fun_more.png);
            size: contain;
          }
          right: -8px;
          top: 0;
          z-index: 3;
        }
      }
      .time_box{
        @include bbox;
        padding-left: 4px;
        width: 100%;
        font-size: 12px;
        color:#666;
        border-left: 4px solid #e5e5e5;
        height: 12px;
        line-height: 12px;
        .check_status_group {
          color: #F78900;
          &.fail {
            color: #D82E0D;
          }
          &.approve {
            color: #05AD00;
          }
        }
      }

    }
    .template_info_group{
      @include flexsb;
      @include bbox;
      height: 40px;
      padding: 0 16px;
      border-radius: 0 0 10px 10px;
      background-color:#fff;
      &.separate{
        border-top: 2px solid #f8f8f8;
      }
      .avatar_group{
        @include flex;
        .avatar{
          display: block;
          width:26px;
          height: 26px;
          border-radius: 4px;
          overflow: hidden;
        }
        .new_chat_icon{
          margin-left: 8px;
          height: 19px;
          width: 20px;
        }
      }
      .tag_box{
        @include flex;
        justify-content: flex-end;
        .tag{
          font-size: 12px;
          line-height: 19px;
          padding: 0 7px;
          margin-left:4px;
          border-radius: 2px;
          &.finance {
            color: #D32F2F;
            background-color: #FFEBEE;
          }
          &.privacy{
            color: #8C73F1;
            background-color: rgba(83, 47, 230, 0.08);
          }
          &.teach{
            color: #FF7200;
            background-color: rgba(255, 114, 0, 0.08);
          }
          &.draft{
            color: #5EC799;
            background-color: rgba(31, 175, 112, 0.08);
          }
          &.reminder{
            width: 20px;
            height: 20px;
            padding: 0;
            background-image: url(~@/assets/images/task/task_thumbnail_reminder.png);
          }
        }
      }
      .check_status{
        font-size:14px;
        color:#05AD00;
      }
      .teaching_group{
        flex: 1;
        .putaway{
          width: 54px;
          height: 22px;
          display: block;
        }
        .audit{
          font-size: 14px;
        }
      }
    }

    &.loadmore{
      @include flex;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      border:none;
    }
  }
  .from_group{
    @include flex;
    @include bbox;
    padding-left: 6px;
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
    margin-bottom: 10px;
    .tag{
      flex-shrink: 0;
    }
    .from{
      @include single_line_intercept;
      @include bbox;
      padding-left: 5px;
      width: 100%;
      flex:1;
      color:#1B6FC2;
    }
  }
  .content_group{
    @include bbox;
    padding: 19px 16px;
    background-color: #fff;
    height: 105px;
    position: relative;
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .content{
      @include multi_line_intercept(4);
      font-size:12px;

      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:18px;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      word-break: break-all;
    }
  }
  .info_group{
    @include flexsb;
    @include bbox;
    padding: 0 16px;
    width:100%;
    height: 35px;
    .time{
      font-size:12px;

      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .tag{
      width: 14px;
      height: 18px;
      background: {
        image: url(~@/assets/images/fun_template/tag.png);
      }
    }
    .check_status{
      font-size:14px;
      color:#05AD00;
    }
  }
}
</style>
<style lang="scss" scoped>
.more_list{
  .list_group{
    @include transition;
    @include flex;
    padding: 0 15px;
    &:hover{
      // background-color: rgba(0,0,0,0.4);
      background-color: #eee;
    }
    &.no_icon{
      .text{
        padding-left: 0;
      }
    }
    .text{
      // color: #fff;
      font-size: 14px;
      line-height: 40px;
      // padding-left: 13px;
    }
    .icon{
      width: 20px;
      height: 20px;
    }
  }
}

</style>
<style scope>
.components_common_thumb_task_page_popover{
  /* background-color: #333; */
  padding: 0;
  border-radius: 4px;
  min-width: 120px!important;
}
</style>
